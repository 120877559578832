import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

// Learnings and tools
import { ReactComponent as DesignIcon } from './svg/design.svg';
import { ReactComponent as FigmaIcon } from './svg/figma.svg';
import { ReactComponent as ReactIcon } from './svg/react.svg';
import { ReactComponent as StorybookIcon } from './svg/storybook.svg';
import { ReactComponent as MaterialIcon } from './svg/materialdesign.svg';
import { ReactComponent as MUIIcon } from './svg/mui.svg';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Image from '@common/Image';
import { PageSEO } from '@common/SEO';

import {
  Section,
  Container,
  DesignText,
  // TechnologyText,
  // RunningText,
} from '@components/global';

import {
  Content,
  PageTitle,
  Title,
  Paragraph,
  List,
  Item,
  LearningsGrid,
  Learning,
  LearningTopic,
  // Element,
} from '@components/pages';

import Footer from '@sections/Footer';

import meta from './address-input-field.json';
const { title, description } = meta;

const PortfolioPage = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        designs: allFile(
          filter: { absolutePath: { regex: "/address/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 900, quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
        seo: allFile(
          filter: {
            absolutePath: { regex: "/address/" }
            name: { eq: "thumbnail" }
          }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fixed(width: 1200) {
                  src
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Layout>
        <PageSEO
          title={title}
          description={description}
          img={data.seo.edges[0].node.childImageSharp.fixed.src}
          location={location}
        />
        <Navbar page />

        <Section>
          <Container>
            <Content>
              <PageTitle>
                <DesignText>{title}</DesignText>
              </PageTitle>
              <Paragraph>{description}</Paragraph>
              <Image
                edges={data.designs.edges}
                name="thumbnail"
                alt="Designs for the address input field"
                overflow
              />

              <LearningsGrid>
                <Learning>
                  <DesignIcon />
                  <LearningTopic>Product Design</LearningTopic>
                </Learning>

                <Learning>
                  <FigmaIcon />
                  <LearningTopic>Figma</LearningTopic>
                </Learning>
                <Learning>
                  <ReactIcon />
                  <LearningTopic>React</LearningTopic>
                </Learning>

                <Learning>
                  <StorybookIcon />
                  <LearningTopic>Storybook</LearningTopic>
                </Learning>
                <Learning>
                  <MaterialIcon />
                  <LearningTopic>Material Design</LearningTopic>
                </Learning>
                <Learning>
                  <MUIIcon />
                  <LearningTopic>Material-UI</LearningTopic>
                </Learning>
              </LearningsGrid>

              <Title>Goal</Title>

              <Paragraph>
                The goal of this project was to design from scratch an address
                input field, given this was the single most troublesome
                component our end users were complaining about.
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="A02-Issues"
                overflow
                alt="Examples of issues and complaints"
                description="Screenshot of the existing address field and examples of issues and complaints recorded via customer support"
              />

              <Title>Hypothesis</Title>

              <Paragraph>
                A series of hypothesis were laid out on why the experience was
                so badly percieved and why the component had been implemented
                the way it currently was. On those that validation was possible
                against hard data, we did.
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="A03-Hypothesis"
                overflow
                alt="Description of hypothesis"
                description="Hypothesis made on the usage and validations"
              />

              <Title>Problem statement</Title>

              <Paragraph>
                It was ascertained that one of the main causes for how the
                component had been set up laid on the underlying database
                structure: addresses, for governments, are a critical piece of
                data because any procedure require legally valid notifications.
                Up until recent years, those were postal notifications.
              </Paragraph>

              <Paragraph>
                Because of that, precise postal addresses were crucial and the
                database model captured all that precision. But for the average
                citizen, the extra complexity would never be needed as the
                average address is fairly simple.
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="A04-Problem"
                overflow
                alt="Problem statement and user and business goals"
                description="Definition of the problem and characteristics of stakeholders' goals"
              />

              <Title>Benchmark</Title>

              <Paragraph>
                We carried out research to review how the collection of
                addresses was being done on government agencies and on other use
                cases (utilities, e-commerce, mapping services...).
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="A05-Benchmark"
                overflow
                alt="Market benchmark screenshots"
                description="Screenshots of how this component is implemented on other services"
              />

              <Title>Constraints</Title>

              <Paragraph>
                We identified 3 possible approaches: a full form with many input
                fields, a wizard that helps the user by breaking the address
                into steps, and a search field.
              </Paragraph>

              <Paragraph>
                We pitted each of these approaches against the technical
                constraints that our database model, and our customer's usage,
                imposed.
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="A06-Constraints"
                overflow
                alt="List of technical constraints"
                description="3 different possible approaches and what technical constraints we face"
              />

              <Title>Solution</Title>

              <Paragraph>
                Based on research, the constraints above, and the user
                experience we wanted to achieve, a feasible solution emerged.
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="A07-Solution"
                overflow
                alt="Definition of the solution"
                description="Agreed solution to be implemented"
              />

              <Title>Ideation</Title>

              <Paragraph>
                Initial drafts, explorations and final designs were worked on
                Figma and reviewed by the team. Many iterations were needed to
                find the final solution matching the existing visual design with
                the desired user experience.
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="A08-Ideation-1"
                overflow
                alt="Sketch"
                description="Lo-fi initial sketches"
              />

              <Image
                edges={data.designs.edges}
                name="A08-Ideation-2"
                overflow
                alt="Exploratory designs"
              />
              <Image
                edges={data.designs.edges}
                name="A08-Ideation-3"
                overflow
                alt="Exploratory designs"
              />
              <Image
                edges={data.designs.edges}
                name="A08-Ideation-4"
                overflow
                alt="Exploratory designs"
              />
              <Image
                edges={data.designs.edges}
                name="A08-Ideation-5"
                overflow
                alt="Exploratory designs"
                description="Detailed exploratory designs"
              />

              <Title>Implementation</Title>

              <Paragraph>
                The new control was added to our existing design system,
                tailored to our product's visual style and based on Material
                Design guidelines using primitives from MUI.
              </Paragraph>

              <Image
                edges={data.designs.edges}
                name="A09-Implementation-1"
                overflow
                alt="Screenshot of the control"
              />
              <Image
                edges={data.designs.edges}
                name="A09-Implementation-2"
                overflow
                alt="Screenshot of the control"
              />
              <Image
                edges={data.designs.edges}
                name="A09-Implementation-3"
                overflow
                alt="Screenshot of the control"
              />
              <Image
                edges={data.designs.edges}
                name="A09-Implementation-4"
                overflow
                alt="Screenshot of the control"
              />
              <Image
                edges={data.designs.edges}
                name="A09-Implementation-5"
                overflow
                alt="Screenshot of the control"
              />
            </Content>
          </Container>
        </Section>

        <Footer neutral />
      </Layout>
    )}
  />
);

export default PortfolioPage;
